import React, { Component } from 'react';
import { Button, Modal, TextInput } from 'flowbite-react';

export class TokenModal extends Component {
    render() {
        return (
            <>
                <Modal show={this.props.show} size="md" popup>
                    <Modal.Header />
                    <Modal.Body>
                        <form onSubmit={this.props.handleKeySubmit}>
                            <div className="space-y-6">
                                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                                    Enter key
                                </h3>
                                <div>
                                    <TextInput id="key" name="key" placeholder="" required />
                                </div>
                                <div className="w-full">
                                    <Button type="submit">Authenticate</Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default TokenModal;
