import { Button, Table } from 'flowbite-react';
import { AiOutlinePause } from 'react-icons/ai';
import { IoPlayOutline, IoCloseOutline } from 'react-icons/io5';

import React from 'react';

const OperationTypes = {
    0: 'DEPOSIT',
    1: 'WITHDRAW',
    2: 'EXCHANGE',
    DEPOSIT: 0,
    WITHDRAW: 1,
    EXCHANGE: 2,
};

const Operation = ({ operation, sendCommand }) => {
    const renderPauseButton = () => {
        return (
            <Button
                gradientDuoTone="redToYellow"
                onClick={(e) => {
                    sendCommand('pauseOperation', { token: operation.token });
                    console.log(operation.username);
                }}
            >
                <AiOutlinePause className="h-5 w-5"></AiOutlinePause>
            </Button>
        );
    };

    const renderResumeButton = () => {
        return (
            <Button
                gradientDuoTone="tealToLime"
                onClick={(e) => sendCommand('resumeOperation', { token: operation.token })}
            >
                <IoPlayOutline className="h-5 w-5"></IoPlayOutline>
            </Button>
        );
    };

    const renderQuitButton = () => {
        return (
            <Button
                gradientDuoTone="pinkToOrange"
                onClick={(e) => sendCommand('quitOperation', { token: operation.token })}
            >
                <IoCloseOutline className="h-5 w-5"></IoCloseOutline>
            </Button>
        );
    };

    return (
        <Table.Row>
            <Table.Cell>{operation.token.slice(0, 12)}...</Table.Cell>
            <Table.Cell>{new Date(operation.timeStarted * 1000).toLocaleString()}</Table.Cell>
            <Table.Cell>{OperationTypes[operation.type]}</Table.Cell>
            <Table.Cell>{operation.username}</Table.Cell>
            <Table.Cell>{operation.depoWorld}</Table.Cell>
            <Table.Cell>{operation.exchangeWorld}</Table.Cell>
            <Table.Cell>{operation.amount}</Table.Cell>
            <Table.Cell>
                <Button.Group outline>
                    {operation.paused ? renderResumeButton() : renderPauseButton()}
                    {renderQuitButton()}
                </Button.Group>
            </Table.Cell>
        </Table.Row>
    );
};

export default Operation;
