import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Button, Flowbite, Table } from 'flowbite-react';
import useWebSocket from 'react-use-websocket';
import Operation from './components/Operation';
import TokenModal from './components/TokenModal';
import './App.css';

function App() {
    const [operations, setOperations] = useState([]);
    const [maintenance, setMaintenance] = useState([]);
    const [key, setKey] = useState(''); //iQtWXBNpuemnUnCn18t5Y1z0DvpxtIW5
    const [showModal, setShowModal] = useState(true);
    const prevOperationsRef = useRef([]);

    const onMessage = (message) => {
        const info = JSON.parse(message.data);

        setMaintenance(info['maintenanceStatus']);

        const receivedOperations = info['operations'] ?? [];

        // Create a set of unique ids from received operations
        const receivedIds = new Set(receivedOperations.map((op) => op.token));

        // Filter out operations from previous operations that are no longer present
        let ongoingOperations = prevOperationsRef.current.filter((op) => receivedIds.has(op.token));

        // Identify and update operations with changed 'paused' status
        for (const newOp of receivedOperations) {
            const oldOp = ongoingOperations.find((op) => op.token === newOp.token);
            if (oldOp && oldOp.paused !== newOp.paused) {
                // Remove the old operation and add the updated one
                ongoingOperations = ongoingOperations.filter((op) => op.token !== newOp.token);
                ongoingOperations.push(newOp);
            }
        }

        // Identify and notify about genuinely new operations
        const newOperations = receivedOperations.filter(
            (op) => !prevOperationsRef.current.some((oldOp) => oldOp.token === op.token)
        );

        for (const operation of newOperations) {
            if (operation.amount >= 30000 && operation.type === 1) {
                new Notification(`New withdrawal (${operation.amount})`);
            }
        }

        // Merge ongoing and new operations
        let mergedOperations = [...ongoingOperations, ...newOperations];

        mergedOperations = mergedOperations.sort((a, b) => {
            return a['timeStarted'] - b['timeStarted'];
        });

        setOperations(mergedOperations);
    };

    const { sendJsonMessage } = useWebSocket(
        process.env.REACT_APP_OPERATOR_WS_URL + '?key=' + key,
        {
            onOpen: () => console.log('Connected successfully'),
            onMessage: onMessage,
            shouldReconnect: (closeEvent) => true,
        }
    );

    const sendCommand = useCallback(
        (messageID, args) => {
            sendJsonMessage({ ID: messageID, ...args });

            console.log('Sending', messageID);
            if (messageID !== 'requestStatus') sendCommand('requestStatus');
        },
        [sendJsonMessage]
    );
    useEffect(() => {
        if (!key) return;

        Notification.requestPermission();

        const interval = setInterval(() => {
            sendCommand('requestStatus');
        }, 1000);

        return () => {
            console.log('Closing');
            clearInterval(interval);
        };
    }, [key, sendCommand]);

    useEffect(() => {
        prevOperationsRef.current = operations;
    }, [operations]);

    const handleMaintenanceStart = (operation) => {
        sendCommand('setMaintenance', { maintenanceStatus: !maintenance });
    };

    const handleKeySubmit = (e) => {
        e.preventDefault();
        console.log(e);
        setKey(e.target.key.value);
        setShowModal(false);
    };

    return (
        <div className="App">
            <Flowbite theme={{ dark: true }}>
                <div className="min-h-screen bg-gray-900 p-8 text-white">
                    <div>
                        <TokenModal handleKeySubmit={handleKeySubmit} show={showModal}></TokenModal>
                        <Button
                            gradientDuoTone="purpleToPink"
                            onClick={handleMaintenanceStart}
                            outline
                        >
                            <p>{maintenance ? 'Disable' : 'Enable'} maintenance</p>
                        </Button>
                        <div className="p-2"></div>
                        <Table>
                            <Table.Head>
                                <Table.HeadCell>Token</Table.HeadCell>
                                <Table.HeadCell>Time</Table.HeadCell>
                                <Table.HeadCell>Type</Table.HeadCell>
                                <Table.HeadCell>Username</Table.HeadCell>
                                <Table.HeadCell>Deposit World</Table.HeadCell>
                                <Table.HeadCell>Exchange World</Table.HeadCell>
                                <Table.HeadCell>Amount</Table.HeadCell>
                                <Table.HeadCell>Actions</Table.HeadCell>
                            </Table.Head>
                            <Table.Body>
                                {operations.map((operation, index) => (
                                    <Operation
                                        key={index}
                                        operation={operation}
                                        sendCommand={sendCommand}
                                    />
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                </div>
            </Flowbite>
        </div>
    );
}

export default App;
